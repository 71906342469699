.messages {
    padding: 16px;
    overflow: hidden scroll;
}

.messagesClosed {
    height: calc(100vh - 200px);
}

.message {
    font-size: 12px;
    color: #555;
    display: flex;
    justify-content: center;
}
