.message {
    display: flex;
    flex-direction: column;
}

.inbox {
    align-items: flex-start;
}

.outbox {
    align-items: flex-end;
}

.messageText {
    padding: 12px 20px;
    border-radius: 3px;
    font-size: 14px;
    margin-bottom: 4px;
    max-width: 80%;
}

.bgWhite {
    background-color: #f8f9fa;
}

.bgOrange {
    background: linear-gradient(to top, rgba(254, 101, 49, 1), rgba(254, 101, 49, 1));
    color: #fff;
}
