.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    margin-bottom: 4px;
}

.attachFile {
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.file {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    gap: 10px;
    border-radius: 5px;
    margin-bottom: 4px;
}

.download {
    display: flex;
    width: 24px;
    height: 24px;
    border: solid 1px #e6ebf5;
    border-radius: 3px;
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
    align-self: flex-end;
}

.download img {
    object-fit: contain;
}

.inbox {
    align-items: flex-start;
}

.outbox {
    align-items: flex-end;
}

.bgWhite {
    background-color: #f8f9fa;
}

.bgOrange {
    background: linear-gradient(to top, rgba(254, 101, 49, 1), rgba(254, 101, 49, 1));
    color: #fff;
}
