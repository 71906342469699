.newMessage {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    gap: 8px;
    padding: 24px;
}

.enterText {
    padding: 0.5rem 1rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #2a2f34;
    background-clip: padding-box;
    background: rgb(246,246,249);
    border-radius: 5px;
    border: none;
}

.enterText:focus {
    outline: none;
}

.submit {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: transparent;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit svg {
    width: 22px;
    height: 22px;
}

.submit:disabled {
    opacity: 0.65;
    border: solid 1px;
}

.fileList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.fileList__file {
    background: linear-gradient(to top, rgba(254, 101, 49, 1), rgba(254, 101, 49, 1));
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    position: relative;
}

.fileList__fileRemove {
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 0;
    top: -8px;
    right: -8px;
    display: block;
    border-radius: 50%;
    background: rgb(246,246,249);
    cursor: pointer;
    border: 1px solid #212529;
}
