.call {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 22px;
    height: 22px;
    padding: 2px;
    color: #9397ab;
}

.callModal {
    padding: 0;
    max-width: 500px;
    width: 100%;
    border-radius: 6px;
}

.callModalClose {
    display: none;
}
