.formGroup {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Roboto', Arial, sans-serif;
}

.fromLabel {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
    text-align: center;
}

.formInput {
    border-radius: 5px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    border: solid 1px #e6ebf5;
    background-color: #fff;
}

.formInput:focus {
    box-shadow: 0 0 0 0 rgba(97,83,204,.25);
    border-color: #b0a9e6;
    outline: none;
}

.formInput:disabled {
    background-color: #fafafa;
}
