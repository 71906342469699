.chat {
    max-width: 1130px;
    margin: 0 auto;
    background-color: #fff;
    height: calc(100vh - 20px);
    display: grid;
    grid-template-rows: max-content 1fr max-content;
}

.chat__header {
    border-bottom: 1px dashed #e6ebf5;
    padding: 24px;
}

.chat__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat__footer {
    padding-bottom: 30px;
    color: rgba(42, 47, 52, 0.75);
    font-size: 12px;
    text-align: center;
    border-top: 1px solid rgba(0,40,100,.12);
}

.chat__footer_closed {
    padding-top: 30px;
}

.chatTitle {
    font-weight: 500;
    line-height: 1.2;
    color: #212529;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chatModal {
    max-width: 500px;
    width: 100%;
    border-radius: 6px;
    background-color: #fafafa;
    padding: 24px;
}

@media screen and (max-width: 767px) {
    .chatModal {
        max-width: 240px;
    }
}
