.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.invoice {
    margin: 10px 0 4px;
    background-color: #FE6531;
    border-radius: 5px;
}

.invoiceStatus {
    text-align: center;
    margin: 14px 0 10px;
    color: white;
    font-weight: 300;
}

.body {
    background-color: #FFE8E0;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #2a2f34;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
}

.invoiceDetails {
    list-style: none;
    padding: 0;
    margin: 0;
}

.invoiceDetails li {
    margin-bottom: 16px;
}

.link {
    font-size: 11px;
    color: #6153CC;
    text-decoration: none;
}
.link:hover {
    text-decoration: underline;
}
