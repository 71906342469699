.body {
    font-family: 'Inter', "Helvetica Neue", Arial, sans-serif;
    background-color: #FFF3EF;
    min-height: 100vh;
    color: #1D2445;
}

.container {
    max-width: 375px;
    padding: 60px 30px;
    margin: 0 auto;
    box-sizing: border-box;
}

.title {
    padding-bottom: 10px;
    margin: 0;
    color: inherit;
    clear: both;
}

.group {
    display: grid;
    padding-top: 20px;
    gap: 5px;
}

.group__title {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
}

.group__input {
    border-radius: 12px;
    box-shadow: 0 3px 6px rgba(254,101,49, 0.15);
    background-color: #fff;
    font-size: 14px;
    padding: 12px 14px;
    border: none;
}

.group__input:focus {
    outline: none;
}

.submit {
    font-weight: 700;
    font-size: 20px;
    width: 100%;
    height: 60px;
    border-radius: 12px;
    background-color: #62CA63;
    border: none;
    color: #fff;
    cursor: pointer;
    margin: 20px 0 32px;
}

.text {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    opacity: .7;
}

.text__list {
    list-style: "- ";
    padding-left: 10px;
}
